/* Existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Updated navbar styles */
.custom-navbar {
  background: rgba(0, 0, 0, 0.8);
 
  border-bottom-right-radius: 20px;
}

.navbar-dark .nav-link {
  color: #f8f9fa !important; /* Light color for links */
  transition: color 0.2s ease-in-out; /* Smooth transition for hover effect */
  font-size: 0.9rem; /* Smaller font size */
  margin: 0 10px; /* Spacing between links */
}

.navbar-dark .nav-link:hover {
  color: #cbd3da !important; /* Slightly darker color on hover */
}

.navbar-brand img {
  height: 30px; /* Adjust to fit your logo size */
  transition: transform 0.2s ease-in-out; /* Smooth transition for logo hover effect */
  padding-left: 1rem; 
}

.navbar-brand:hover img {
  transform: scale(1.1); /* Slightly increase logo size on hover */
}

/* Adjusting existing navbar styles to fit the new structure */
.navbar {
  height: auto; /* Allows the navbar to adjust height based on content */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures logo and login link are on opposite ends */
  padding: 0.5rem 1rem; /* Standard Bootstrap navbar padding */
}

.navbar a {
  text-decoration: none;
  color: #fff; /* White text color, consider using .nav-link for consistency */
}

/* You might not need this with Bootstrap's navbar-toggler */
.navbar .icon {
  display: none; /* Hide custom icon in favor of Bootstrap's navbar-toggler */
}
