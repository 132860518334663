.dashboard-container {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .persona-selection {
    margin-bottom: 20px;
  }
  
  .persona-details {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .share-section {
    display: flex;
    align-items: center;
  }
  
  .share-link {
    margin-left: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .copy-success-message {
    color: green;
    font-weight: bold;
  }
  
  .narrow-dropdown {
    width: 400px;  /* Set a specific width */
    max-width: 400px;  /* Ensure it doesn't grow beyond this */
}
.persona-dropdown {
    max-width: 300px;
    margin: auto;
}
  
.create-persona-btn {
  border-radius: 25px;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid #007BFF;
  color: #007BFF;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.create-persona-btn:hover {
  background-color: #007BFF;
  color: #FFFFFF;
}

.left-align-text {
  text-align: left;
}
.description-info-text {
  font-size: 0.9rem;
  color: #555;
  font-style: italic;
  background-color: #f8f8f8;
  border-left: 3px solid #007bff;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
  text-align: left;
}

.placeholder-style::placeholder {
  color: #999; /* Lighter color */
  font-style: italic; /* Italic font style */
}

.custom-button-size {
  width: 135px; /* Adjust width as needed */
  height: 80px; /* Adjust height as needed */
}



.custom-number-input .styled-number-input {
  /* Add your custom number input styles here */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  font-size: 1rem;
}

/* Optional: Style for the checkbox - example */
.custom-checkbox .styled-checkbox:after {
  content: '✔';
  display: none;
  color: #007bff;
}

.custom-checkbox .styled-checkbox:checked:after {
  display: inline-block;
}

/* Initial State (Hidden) */
.assistants-gallery {
  opacity: 0;
  max-height: 0;
  overflow: hidden; /* Hide content when gallery is collapsed */
  transition: opacity 0.8s ease-in-out, max-height 0.8s ease-in-out;
}

/* Final State (Visible) */
.assistants-gallery.visible {
  opacity: 1;
  max-height: none; /* Remove max-height restriction */
  overflow-y: auto; /* Make gallery scrollable vertically */
  max-height: 1000px; /* Set a reasonable max-height for visibility */
}

.toggle-assistants-button {
  width: auto; /* Adjust as needed, e.g., 50%, 200px, etc. */
  display: inline-block; /* Makes the button only as wide as its content */
}

/* Dashboard.css or another relevant stylesheet */
.btn-outline-green {
  color: #28a745; /* Bootstrap green color */
  border-color: #28a745;
}

.btn-outline-green:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.required-symbol {
  color: #ff0000; /* Red color for visibility */
}

.note-required-fields p {
  font-size: 14px; /* Adjust size as needed */
  color: #666; /* Subtle text color */
}
