

.chat-wrapper {
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: blue;
}

.chat-container {
    max-height: 400px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.chat-message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 90%;
    text-align: left;
}

.chat-message.assistant {
    background-color: #f1f1f1; /* Light gray color */
    align-self: flex-start;
    min-width: 85%;
}

.chat-message.user {
    background-color: #9932CC; /* Updated to a vibrant blue color */
    align-self: flex-end;
    color: #fff; /* White text color for better readability */
}

.chat-message span {
   
    margin-bottom: 5px;
    display: block;
}

.chat-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

.send-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #9932CC;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.send-button:hover {
    background-color: #0056b3;
}


@keyframes dotPulse {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}

.typing-dots span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #007BFF;
    border-radius: 50%;
    animation: dotPulse 1.4s infinite;
}

.typing-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-dots span:nth-child(3) {
    animation-delay: 0.4s;
}
.persona-image-container {
    text-align: center;
    margin-bottom: 20px;
}

.persona-image {
    border-radius: 10%;
    width: 300px;
    height: auto;
    max-width: 100%;
}



/* Default scaling for larger screens */
.iframe-scaling {
    transform: scale(1);
    transform-origin: top left;
    overflow: hidden;
}

/* Scaling for medium screens, e.g., widths less than 800px */
@media (max-width: 800px) {
    .iframe-scaling {
        transform: scale(0.85); /* Slightly smaller */
    }
}

/* Scaling for smaller screens, e.g., widths less than 500px */
@media (max-width: 500px) {
    .iframe-scaling {
        transform: scale(0.5); /* Even smaller */
    }
}


.iframe-assistant-name {
    font-size: 1.5em; /* Adjust the font size as needed */
    color: #333; /* Adjust the color as needed */
}

.iframe-persona-image {
    border-radius: 10%;
    width: 150px;
    height: auto;
    max-width: 100%;
}

/* Styles for the student name explanation section */
.student-name-explanation {
    background-color: #f9fafb;
    border: 1px solid #d1d3e2;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    margin: 10px 0;
}

/* Styles for the student name input text */
.student-name-text {
    font-weight: bold;
    color: #333;
    font-size: 1rem; /* Changed to kebab-case */
    margin-bottom: 10px; /* Changed to kebab-case */
}

/* Styles for the student name input field */
.student-name-input {
    font-size: 1rem; /* Changed to kebab-case */
    padding: 10px;
    border-radius: 5px; /* Changed to kebab-case */
    border: 1px solid #d1d3e2;
    width: 80%;
    max-width: 300px; /* Changed to kebab-case */
}

/* Additional styles for iframe context */
.iframe-student-name-explanation {
    /* Adjust styles for smaller size, padding, etc., as needed for the iframe */
    padding: 12px; /* Example: Smaller padding */
}

.iframe-student-name-text {
    
    font-size: 0.8rem; /* Changed to kebab-case */
}

.iframe-student-name-input {
   
    font-size: 0.8rem; /* Changed to kebab-case */
}






.chat-wrapper {
    /* Define a specific width and height or max-width and max-height for the chat-wrapper */
    max-width: 600px; /* Example max-width */
    width: 95%;
    margin: 0 auto; /* For horizontal centering */
    background-color: transparent;
}

.chat-wrapper.iframe-scaling {
    --bs-body-bg: transparent;
}

.mathquill-input {
    display: inline-block; /* or 'block' depending on your layout needs */
    min-width: 100px; /* Adjust this value as needed */
    /* Additional styling as needed */
}

div.hidden {
    display: none !important;
}

.cm-content {
    text-align: left !important;
  }
  

  .cm-line span {
    display: inline !important;
  }

  .pop-out-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border: 2px solid #000;
    border-radius: 10px;
    background-color: #f1f1f1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    box-sizing: border-box;
    overflow: hidden; /* Set to hidden to control overflow within pop-out-scrollable-content */
    width: 95%; /* Adjust this for responsive width */
    max-width: 600px; /* Maximum width - adjust as needed */
    height: 80vh; /* Adjust as needed for viewport height */
  }
  
  .pop-out-scrollable-content {
    max-height: 80vh; /* Adjust based on your requirements */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-top: 40px;
    padding-right: 20px; 
    padding-left: 20px; 
    text-align: left;
    padding-bottom: 20px;
  }
  
  .pop-out-close {
    position: absolute;
    top: 5px; 
    right: 10px; 
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px; 
    padding-right: 10px;
    color: #9a1a03; /* Adjust the color as needed */
  }
  
  .pop-out-new-window {
    position: absolute;
    top: 10px;
    right: 50px; /* Adjust as needed */
    /* Other styling as required */
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Add space between the buttons and the start of the message */
}

.expand-collapse-btn, .audio-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
    background-color: #e6e6e6;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: 7px; /* Add space between the buttons */
}

.audio-btn.playing {
    background-color: #ffcccc;
}

.audio-status {
    font-size: 0.7rem;
    color: gray;
    opacity: 0.6;
    margin-left: 3px;
    margin-top: 5px;
}

.button-container-left {
    display: flex;
    align-items: center;
}

.expand-collapse-btn-bottom {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 0.9rem;
    background-color: #e6e6e6;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 10px;
    right: 8px;
    height: 27px;
}

.audio-icon {
    font-size: 0.8rem; /* Adjust the size as needed */
}




