.chat-history-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.chat-history-item {
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
}

.student-name {
    font-weight: bold;
    margin-bottom: 8px;
}

.date-time {
    color: #666;
    font-size: 0.9em;
}

.date-group {
    margin-bottom: 30px;
}

.date-header {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
    text-align: left;
}

.chat-history-container {
    max-height: 100%; /* Set to full height of parent container */
    overflow-y: auto; /* Enables vertical scrolling */
    padding: 10px;
    border: 1px solid #ccc; /* Optional border */
    border-radius: 5px; /* Rounded corners */
    margin-top: 20px;
}

.date-group {
    margin-bottom: 30px;
}

.date-header {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
    text-align: left;
}

.chat-history-item {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.student-name {
    font-weight: bold;
}

.date-time {
    font-size: 0.85em;
    color: #666;
}

/* Additional styling as needed */
.assistant-name {
    font-size: 16px; /* Adjust the size as needed */
}

.assistant-description {
    font-size: 14px; /* Adjust the size as needed */
}



.assistants-gallery-container {
    position: relative;
  }
  
  .delete-button-container {
    text-align: right; /* Align the button to the left */
    margin-bottom: 10px; /* Space between the button and the gallery */
  }

  .assistant-checkbox {
    
    display: block; /* Makes the checkbox a block element, placing it on a new line */
    /* Add any additional styling for the checkbox */
  }
  
  
  .assistant-details {
    display: inline-block; /* Align details next to the checkbox */
    vertical-align: middle; /* Adjust vertical alignment */
    cursor: pointer; /* Change cursor on hover */
    /* Add any additional styling for the details section */
  }

  .form-check.form-switch {
    justify-content: start; /* Aligns items to the start (left) */
    text-align: left; /* Aligns text to the left */
    margin-left: 15px;
  }
  
  .form-check-label {
    margin-left: 0.5rem; /* Adds a little space between the switch and the label */
  }
  
  .assistant-details-extra {
    text-align: left;
    font-size: 0.7em;
  }
  
  .assistant-details-extra label {
    font-weight: bold; /* Makes labels bold */
    margin-right: 5px; /* Adds spacing between label and value */
  }
  
  .gallery1 {
    width: 100%;
    /* Remove the grid layout */
}

.category-container {
  margin-bottom: 20px; /* Space between categories */
  width: 100%; /* Full width for each category container */
}

.sub-category-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust 'minmax' as needed */
  gap: 20px; /* Space between items */
}

.sub-category-column {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax values as needed */
    gap: 20px; /* Space between items in the grid */
  }
  


.category-header {
    font-size: 1.4em;
    margin-top: 30px;
    color: #0056b3; /* or any color you prefer */
  }
  
  .sub-category-header {
    font-size: 1.2em;
    margin-top: 20px;
    color: #007bff; /* or any color you prefer */
  }
  
  