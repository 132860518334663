/* CSS for increased spacing and modern styling of buttons */
.landing-page .btn {
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.landing-page .btn-primary {
    background-color: #007bff;
    border: none;
}

.landing-page .btn-primary:hover {
    background-color: #0056b3;
}

.landing-page .btn-light {
    background-color: #f8f9fa;
    border: none;
}

.landing-page .btn-light:hover {
    background-color: #e2e6ea;
}
