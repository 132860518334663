/* Styles for the popup background overlay */
.chat-thread-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 1000; /* High z-index to ensure it's above other elements */
}

/* Styles for the popup container */
.chat-thread-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 800px; /* Adjust the width as needed */
    max-height: 80%; /* Adjust the height as needed */
    overflow-y: auto; /* Allows scrolling if content is too long */
    z-index: 1001; /* Slightly higher than the overlay */
}

/* Styles for the close button */
.chat-thread-popup button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right; /* Position the close button to the right */
    margin-bottom: 10px; /* Spacing below the button */
}

.chat-thread-popup button:hover {
    background-color: #0056b3;
}

/* Styles for each message item */
.message-item {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
}

/* Styles for message role and content */
.message-item p {
    margin: 0;
    word-break: break-word; /* Ensures long text wraps correctly */
    text-align: left; /* Left-align the text */
}
